import { useCallback, useState } from "react";
import styled from "styled-components";
import { publicRequest } from "../requestMethods";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  background-color: white;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const Button = styled.button`
  width: 40%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
`;

const Register = () => {

  const [details, setDetails] = useState({name:"", username:"", email:"", password:"", confirmPassword:""});
  const navigate = useNavigate();

  const handleChange = (e)=>{
    setDetails({...details, [e.target.name]: e.target.value});
  };

  const handleClick = (e)=>{
    e.preventDefault();
    const register = async ()=>{
      try{
        const {confirmPassword, ...others} = details;
        const res = await publicRequest.post('/auth/register', {
          ...others,
        });
        console.log(res.data);
        console.log("hello")
      }catch(err){
        console.log(err);
      }
    }
    register();
    navigate('/login');
  };

  return (
    <Container>
      <Wrapper>
        <Title>CREATE AN ACCOUNT</Title>
        <Form>
          <Input name="name" value = {details.name} onChange = {handleChange} placeholder="name" />
          <Input name="username" value = {details.username} onChange = {handleChange} placeholder="username" />
          <Input name="email" value = {details.email} onChange = {handleChange} placeholder="email" />
          <Input name="mobile" value = {details.mobile} onChange = {handleChange} placeholder="mobile" />
          <Input type="password" name="password" value = {details.password} onChange = {handleChange} placeholder="password" />
          <Input type="password" name="confirmPassword" value = {details.confirmPassword} onChange = {handleChange} placeholder="confirm password" />
          <Agreement>
            By creating an account, I consent to the processing of my personal
            data in accordance with the <b>PRIVACY POLICY</b>
          </Agreement>
          <Button onClick={handleClick}>CREATE</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
