export const sliderItems = [
    {
      id: 1,
      img: "https://marketplace.canva.com/EAFrp7ygKJY/1/0/900w/canva-white-minimal-summer-sale-discount-clothes-instagram-story-zP_tr3h-vEM.jpg",
      title: "SUMMER SALE",
      desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "f5fafd",
    },
    {
      id: 2,
      img: "https://as1.ftcdn.net/v2/jpg/01/58/63/80/1000_F_158638087_ZLxJeJYFy9hcIvajAEsUdcaxYiYlbO11.jpg",
      title: "AUTUMN COLLECTION",
      desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "fcf1ed",
    },
    {
      id: 3,
      img: "https://i.pinimg.com/736x/7e/a0/a8/7ea0a8fef7a3320c4a605cd187da1d96.jpg",
      title: "LOUNGEWEAR LOVE",
      desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
      bg: "fbf0f4",
    },
  ];

  export const categories = [
    {
      id: 1,
      img: "https://thumbs.dreamstime.com/b/dress-shirts-men-men-shirts-folded-pressed-business-wear-men-dress-shirts-men-cloth-159981593.jpg",
      title: "SHIRT STYLE!",
      cat:"shirt"
    },
    {
      id: 2,
      img: "https://img.freepik.com/free-psd/isolated-white-t-shirt-front-view_125540-1194.jpg?size=626&ext=jpg&ga=GA1.1.1518270500.1698710400&semt=ais",
      title: "T-SHIRT",
      cat:"t-shirt"
    },
    {
      id: 3,
      img: "https://5.imimg.com/data5/NM/LX/MY-42532489/mens-black-jacket-500x500.jpg",
      title: "LIGHT JACKETS",
      cat:"jeans"
    },
  ];

  export const popularProducts = [
    {
      id:1,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
    },
    {
      id:2,
      img:"https://cdn.shopify.com/s/files/1/0101/4832/products/Angela_Natural_Tee.png?v=1606780388",
    },
    {
      id:3,
      img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
    },
    {
      id:4,
      img:"https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
    },
    {
      id:5,
      img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
    },
    {
      id:6,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
    },
    {
      id:7,
      img:"https://www.vintageindustries.nl/download_front/qympzk1762/2217_Arrow_Jacket_Forest.png",
    },
    {
      id:8,
      img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
    },
  ]